import React, { Component } from "react"
import { Link } from "gatsby"

class BlogGrid extends Component {
  render() {
    return (
      <section className="blog-area ptb-100">

      </section>
    )
  }
}

export default BlogGrid
